import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlighter';

import s from './Text.module.css';

const Text = ({ className, children, highlight }) => {
  if (highlight) {
    return (
      <Highlighter search={highlight} matchClass={s.highlight}>
        {children}
      </Highlighter>
    );
  }

  return <div>{children}</div>;
};

Text.propTypes = {
  children: PropTypes.node,
  highlight: PropTypes.string,
  className: PropTypes.string,
};

Text.defaultProps = {
  children: undefined,
  className: undefined,
  highlight: undefined,
};

export default React.memo(Text);
