import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Downshift from 'downshift';
import { useTranslation } from 'react-i18next/hooks';

import Button from '../Button';
import Checkbox from '../Checkbox';

import { ReactComponent as DropTickIcon } from '../../icons/drop-tick.svg';
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CreateIcon } from '../../icons/create.svg';

import s from './Select.module.css';

const Select = ({
  theme,
  className,
  items,
  fixedWidth,
  defaultValue,
  name,
  error,
  label,
  placeholder,
  disabled,
  onChange,
  activeChange,
  loading,
  stretch,
  newLabel,
  onAdd,
  colored,
  selectedItem,
}) => {
  const findItem = value => value && items.find(item => item.value === value);

  const [t] = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isActive, setActive] = useState(false);
  const [isNewActive, setNewActive] = useState(false);
  const [originalValue, setOriginalValue] = useState(defaultValue);
  const [currentItem, setCurrentItem] = useState(findItem(defaultValue));

  const selectItem = item => {
    setCurrentItem(item);
    if (item.type !== 'checkbox') setOpen(false);

    if (item.onClick) {
      item.onClick(item);
    } else {
      onChange(item);
    }
  };

  if (originalValue !== defaultValue) {
    setOriginalValue(defaultValue);
    selectItem(findItem(defaultValue));
  }

  if (defaultValue === undefined && selectedItem !== currentItem) {
    setCurrentItem(selectedItem);
  }

  const onActiveChange = status => {
    setActive(status);
    activeChange(status);
  };

  const getLabel = item => item && (typeof item.label === 'function' ? item.label(t) : item.label);

  // downshift needs object if we control selected item so even if the item is undefined we use empty obj {}
  return (
    <Downshift
      itemToString={item => (item ? item.value : '')}
      isOpen={isOpen}
      onOuterClick={() => setOpen(false)}
      selectedItem={currentItem || {}}
    >
      {({ getToggleButtonProps, getItemProps, getMenuProps, isOpen, inputValue, highlightedIndex }) => (
        <div className={cn(s.root, className, s[theme], { [s.active]: isActive })}>
          <Button
            {...getToggleButtonProps({
              theme,
              stretch,
              fixedWidth,
              disabled: disabled,
              onClick: () => setOpen(!isOpen),
              loading: loading,
              active: isOpen,
              background: colored && currentItem && currentItem.color,
              className: cn(s.toggle, { [s.active]: isOpen }),
            })}
          >
            <span className={s.value}>{label || getLabel(currentItem) || placeholder}&nbsp;</span>
            <DropTickIcon />
            {/* hack for increasing button width based on dropdown longest item */}
            {theme === 'green' &&
              items.map((item, index) => (
                <span key={index} className={s.invisible}>
                  {item.label}
                </span>
              ))}
          </Button>
          <input type="hidden" name={name} defaultValue={inputValue} />
          <div
            {...getMenuProps({
              className: cn(s.dropdown, { [s.visible]: isOpen, [s.withNew]: !!newLabel }),
              onAnimationStart: ({ animationName }) => animationName === s.expanding && onActiveChange(true),
              onAnimationEnd: ({ animationName }) => animationName === s.collapsing && onActiveChange(false),
            })}
          >
            <ul className={s.list}>
              {items.map((item, index) => (
                <li
                  {...getItemProps({
                    key: index,
                    index,
                    item,
                    className: cn(s.item, {
                      [s.colored]: !!item.color,
                      [s.selected]: currentItem === item,
                      [s.highlight]: highlightedIndex === index,
                    }),
                  })}
                  onClick={e => {
                    // fix issue with putting select inside <label> (see Field)
                    e.preventDefault();
                    selectItem(item);
                  }}
                >
                  {item.type === 'checkbox' && <Checkbox className={s.checkbox} defaultChecked={item.checked} />}
                  {item.deletable && (
                    <TrashIcon
                      className={s.del}
                      onClick={e => {
                        e.stopPropagation();
                        item.onDelete();
                      }}
                    />
                  )}
                  <span className={s.itemLabel} style={item.color && { background: item.color }}>
                    {getLabel(item)}
                  </span>
                </li>
              ))}
              {newLabel && (
                <li
                  className={s.new}
                  onMouseEnter={() => setNewActive(true)}
                  onMouseLeave={() => setNewActive(false)}
                  onClick={() => {
                    setOpen(false);
                    onAdd();
                  }}
                >
                  <Button theme="green" icons="only" active={isNewActive}>
                    <CreateIcon />
                  </Button>
                  <span className={s.newLabel}>{newLabel}</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </Downshift>
  );
};

Select.propTypes = {
  selectedItem: PropTypes.object,
  items: PropTypes.array.isRequired,
  colored: PropTypes.bool,
  loading: PropTypes.bool,
  stretch: PropTypes.bool,
  newLabel: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChange: PropTypes.func,
  activeChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fixedWidth: PropTypes.number,
  theme: PropTypes.oneOf(['green', 'toolbar', 'toolbar-white', 'field', 'white']),
};

Select.defaultProps = {
  theme: 'field',
  className: '',
  onChange: () => {},
  onAdd: () => {},
  activeChange: () => {},
  placeholder: undefined,
  defaultValue: undefined,
  fixedWidth: undefined,
  label: undefined,
  newLabel: undefined,
  disabled: false,
  error: false,
  loading: false,
  stretch: false,
  colored: false,
};

export default Select;
