import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next/hooks';
import { useMutation } from 'react-apollo-hooks';
import { loader } from 'graphql.macro';

import Form from '../Form';
import Button from '../Button';
import Field from '../Field';
import { getClientFields } from '../ClientFields';
import { withGraphQLErrors } from '../../utils/apollo';
import { ProfileContext } from '../../utils/auth';

import { ReactComponent as ShowIcon } from '../../icons/show.svg';
import { ReactComponent as HideIcon } from '../../icons/hide.svg';

import s from './ClientForm.module.css';

const SAVE_CLIENT_MUTATION = loader('../../gql/mutations/SaveClient.gql');

const onFormSubmit = async (form, client, setLoading, setErrors, afterSave, saveClient) => {
  const config = getClientFields();
  const fields = { id: client.id };
  Object.keys(form).forEach(key => {
    fields[key] = config[key].parse(form[key]);
  });

  setLoading(true);
  setErrors({});
  const { errors } = await withGraphQLErrors(() => saveClient({ variables: fields }));

  if (errors) {
    setErrors(errors);
    setLoading(false);
  } else {
    afterSave();
  }
};

const ClientField = ({ className, disabled, field, client, errors, loading, autoFocus, ...opts }) => {
  const [t] = useTranslation();
  const { label, name, type, items, min, max } = field;
  return (
    <Field
      autoFocus={autoFocus}
      className={cn(s.field, className)}
      label={label(t)}
      name={name}
      type={type}
      disabled={disabled || loading}
      defaultValue={client[name] || ''}
      error={errors[name]}
      items={items}
      min={min}
      max={max}
      {...opts}
    />
  );
};

const ClientForm = ({ client, afterSave, onCancel }) => {
  const { visibleClientColumns } = useContext(ProfileContext);

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const saveClient = useMutation(SAVE_CLIENT_MUTATION);
  const [t] = useTranslation();
  const fields = getClientFields();

  const visibleOpts = (field1, field2, className) => {
    const invisible =
      !expanded && !visibleClientColumns.includes(field1) && (!field2 || !visibleClientColumns.includes(field2));
    return {
      className: cn(className, { [s.invisible]: invisible, [s.visible]: !invisible }),
      disabled: invisible,
    };
  };

  return (
    <Form
      className={s.root}
      containerClassName={s.fieldContainer}
      onSubmit={form => onFormSubmit(form, client, setLoading, setErrors, afterSave, saveClient)}
    >
      <button type="button" className={s.expander} onClick={() => setExpanded(!expanded)}>
        {expanded ? <HideIcon /> : <ShowIcon />}
        {t(expanded ? 'Только основные' : 'Показать все поля')}
      </button>
      <div className={s.columns}>
        <ClientField
          className={s.single}
          field={fields.email}
          client={client}
          errors={errors}
          loading={loading}
          autoFocus
          required
        />
      </div>
      <div className={s.columns}>
        <ClientField field={fields.firstName} client={client} errors={errors} loading={loading} required />
        <ClientField field={fields.lastName} client={client} errors={errors} loading={loading} />
      </div>
      <div className={s.columns}>
        <ClientField
          className={s.visible}
          field={fields.phone}
          client={client}
          errors={errors}
          loading={loading}
          required
        />
        <ClientField field={fields.city} client={client} errors={errors} loading={loading} {...visibleOpts('city')} />
      </div>
      <div {...visibleOpts('gender', 'bday', s.columns)}>
        <ClientField
          field={fields.gender}
          client={client}
          errors={errors}
          loading={loading}
          {...visibleOpts('gender')}
        />
        <ClientField field={fields.bday} client={client} errors={errors} loading={loading} {...visibleOpts('bday')} />
      </div>
      <div {...visibleOpts('discount', 'bonuses', s.columns)}>
        <ClientField
          field={fields.discount}
          client={client}
          errors={errors}
          loading={loading}
          {...visibleOpts('discount')}
        />
        <ClientField
          field={fields.bonuses}
          client={client}
          errors={errors}
          loading={loading}
          {...visibleOpts('bonuses')}
        />
      </div>
      <div {...visibleOpts('extra1', 'extra2', s.columns)}>
        <ClientField
          field={fields.extra1}
          client={client}
          errors={errors}
          loading={loading}
          {...visibleOpts('extra1')}
        />
        <ClientField
          field={fields.extra2}
          client={client}
          errors={errors}
          loading={loading}
          {...visibleOpts('extra2')}
        />
      </div>
      <div {...visibleOpts('extra3', 'extra4', s.columns)}>
        <ClientField
          field={fields.extra3}
          client={client}
          errors={errors}
          loading={loading}
          {...visibleOpts('extra3')}
        />
        <ClientField
          field={fields.extra4}
          client={client}
          errors={errors}
          loading={loading}
          {...visibleOpts('extra4')}
        />
      </div>
      <div className={s.columns}>
        <ClientField
          field={fields.extra5}
          client={client}
          errors={errors}
          loading={loading}
          {...visibleOpts('extra5', undefined, s.single)}
        />
      </div>
      <div className={s.buttons}>
        <Button fixedWidth={150} center type="submit" loading={loading} children={t('Сохранить')} />
        <Button theme="black" type="button" disabled={loading} children={t('Отмена')} onClick={onCancel} />
      </div>
    </Form>
  );
};

ClientForm.propTypes = {
  client: PropTypes.object.isRequired,
  afterSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ClientForm;
