import { loader } from 'graphql.macro';

const PROFILE_QUERY = loader('../gql/queries/Profile.gql');

const updateProfileInCache = (cache, update) => {
  const { profile } = cache.readQuery({ query: PROFILE_QUERY });
  cache.writeQuery({
    query: PROFILE_QUERY,
    data: { profile: Object.assign({}, profile, update) },
  });
};

export default updateProfileInCache;
