import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next/hooks';

import Text from '../Text';

import { ReactComponent as DropTickIcon } from '../../icons/drop-tick.svg';
import { ReactComponent as PrevIcon } from '../../icons/prev.svg';

import s from './Table.module.css';

const maxPage = (total, limit) => {
  const max = ~~(total / limit);
  const rest = total % limit;
  return rest > 0 ? max : max - 1;
};

const calcPages = (offset, total, limit) => {
  const pagerPositions = 6;
  const max = maxPage(total, limit);
  const current = ~~(offset / limit);

  let start = current < pagerPositions ? 0 : current - pagerPositions / 2;
  start = Math.min(start, max - pagerPositions + 1);
  start = Math.max(start, 0);

  let pages = [];
  let page;

  if (start > 0) {
    pages.push({
      page: 1,
      offset: 0,
    });
    pages.push({
      gap: true,
    });
  }

  for (page = start; page < start + pagerPositions; page++) {
    if (page > max) continue;
    pages.push({
      page: page + 1,
      offset: page * limit,
    });
  }

  if (page < max) {
    pages.push({
      gap: true,
    });
    pages.push({
      page: max + 1,
      offset: max * limit,
    });
  }

  return pages;
};

const Table = ({ tableConfig, total, loading, columns, rows, highlightText, onLoad, onRowSelect }) => {
  const [t] = useTranslation();
  const { offset, limit, orderBy, orderDirection } = tableConfig;

  //convert number e.g. 5 into [0,1,2,3,4]
  const maxRows = Array.from(Array(limit).keys());

  return (
    <div className={s.root}>
      {loading && <div className={s.loader} />}
      <div className={s.tableContainer}>
        <table className={cn(s.table, { [s.noColumns]: columns.length === 0 })}>
          <thead>
            <tr>
              {columns.map(({ label, name, right }) => (
                <th
                  key={name}
                  className={cn({
                    [s.ordered]: orderBy === name,
                    [s.asc]: orderDirection === 'ASC',
                    [s.right]: right,
                  })}
                  onClick={() =>
                    onLoad({
                      ...tableConfig,
                      offset: 0,
                      orderBy: name,
                      orderDirection: orderBy === name && orderDirection === 'ASC' ? 'DESC' : 'ASC',
                    })
                  }
                >
                  {typeof label === 'function' ? label(t) : label}
                  <DropTickIcon className={s.tick} />
                </th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {maxRows.map((_, rowIndex) => (
              <tr
                key={rowIndex}
                className={cn({ [s.filled]: !!rows[rowIndex] && columns.length > 0 })}
                onDoubleClick={() => rows[rowIndex] && onRowSelect(rows[rowIndex])}
              >
                {columns.map(({ render, name, right }, index) => (
                  <td className={cn({ [s.ordered]: orderBy === name, [s.right]: right })} key={name}>
                    {(rows[rowIndex] && <Text highlight={highlightText}>{render(rows[rowIndex], t)}</Text>) || (
                      <span className={s.empty} />
                    )}
                  </td>
                ))}
                <td />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {total > limit && (
        <div className={s.pager}>
          <button onClick={() => onLoad({ ...tableConfig, offset: 0 })}>{t('Начало')}</button>
          <button
            onClick={() => offset - limit > 0 && onLoad({ ...tableConfig, offset: offset - limit })}
            className={s.pagePrev}
          >
            <PrevIcon />
          </button>
          {calcPages(offset, total, limit).map(({ page, gap, offset: newOffset }) =>
            gap ? (
              <span key={page}>...</span>
            ) : (
              <button
                key={page}
                onClick={() => newOffset !== offset && onLoad({ ...tableConfig, offset: newOffset })}
                className={cn(s.pagerButton, { [s.pagerSelected]: newOffset === offset })}
              >
                {page}
              </button>
            ),
          )}
          <button
            onClick={() => offset + limit < total && onLoad({ ...tableConfig, offset: offset + limit })}
            className={s.pageNext}
          >
            <PrevIcon />
          </button>
          <button onClick={() => onLoad({ ...tableConfig, offset: maxPage(total, limit) * limit })}>
            {t('Конец')}
          </button>
        </div>
      )}
    </div>
  );
};

Table.propTypes = {
  tableConfig: PropTypes.shape({
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    orderBy: PropTypes.string.isRequired,
    orderDirection: PropTypes.oneOf(['DESC', 'ASC']).isRequired,
  }).isRequired,
  total: PropTypes.number,
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  highlightText: PropTypes.string,
  onLoad: PropTypes.func.isRequired,
  onRowSelect: PropTypes.func,
};

Table.defaultProps = {
  total: 0,
  loading: false,
  highlightText: undefined,
  onRowSelect: () => {},
};

export default React.memo(Table);
