import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next/hooks';
import Layout from '../../components/Layout';
import AuthPanel from '../../components/AuthPanel';
import Form from '../../components/Form';
import Field from '../../components/Field';
import Checkbox from '../../components/Checkbox';
import Link from '../../components/Link';
import Button from '../../components/Button';

import { ReactComponent as FbIcon } from '../../icons/fb.svg';
import { ReactComponent as VkIcon } from '../../icons/vk.svg';
import { register } from '../../utils/auth';

import s from './Register.module.css';

const onSubmit = async (fields, setLoading, setErrors, history) => {
  setLoading(true);
  setErrors({});
  const [ok, errors] = await register(fields);

  if (!ok) {
    setErrors(errors);
    setLoading(false);
    return;
  }

  history.push('/');
};

const Register = ({ history }) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  return (
    <Layout center title={t('Регистрация')}>
      <AuthPanel>
        <div className={s.root}>
          <div className={s.header}>
            <h1>{t('Регистрация')}</h1>
            <FbIcon />
            <VkIcon />
          </div>
          <Form onSubmit={fields => onSubmit(fields, setLoading, setErrors, history)}>
            <Field
              label={t('Компания')}
              name="company_name"
              type="text"
              disabled={loading}
              error={errors.company_name}
              autoComplete="new-company-name"
              autoFocus
              required
            />
            <Field
              label={t('Имя')}
              name="name"
              type="text"
              disabled={loading}
              error={errors.name}
              autoComplete="new-name"
              required
            />
            <Field
              label={t('E-мейл')}
              name="email"
              type="text"
              disabled={loading}
              error={errors.email}
              autoComplete="new-email"
              required
            />
            <Field
              label={t('Пароль')}
              name="password"
              type="password"
              disabled={loading}
              error={errors.password}
              autoComplete="new-password"
              required
            />
            <Checkbox name="conds" className={s.checkbox} disabled={loading} error={errors.conds}>
              {t('Я даю согласие на обработку персональных данных')}
            </Checkbox>
            <Button type="submit" fixedWidth={200} center>
              {t('Зарегистрироваться')}
            </Button>
          </Form>
          <p className={s.hint}>
            {t('У меня уже есть аккаунт.')}
            &nbsp;
            <Link to="/login">{t('Войти')}</Link>
          </p>
        </div>
      </AuthPanel>
    </Layout>
  );
};

export default withRouter(Register);
