import React from 'react';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { useQuery } from 'react-apollo-hooks';

import { ProfileContext } from '../../utils/auth';

const PROFILE_QUERY = loader('../../gql/queries/Profile.gql');

const ProfileLoader = ({ children }) => {
  const { data } = useQuery(PROFILE_QUERY);
  return <ProfileContext.Provider value={data.profile}>{children}</ProfileContext.Provider>;
};

ProfileLoader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProfileLoader;
