import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import buildTitle from '../../utils/title';

import 'normalize.css';

import s from './Layout.module.css';

const Layout = ({ children, center, title }) => {
  useEffect(() => {
    document.title = buildTitle(title);
  });
  return <div className={cn(s.root, { [s.center]: center })}>{children}</div>;
};

Layout.propTypes = {
  center: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = { center: false };

export default Layout;
