import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next/hooks';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { loader } from 'graphql.macro';

import { withGraphQLErrors } from '../../utils/apollo';
import Select from '../Select';
import Button from '../Button';
import Form from '../Form';
import Modal from '../Modal';

const CLIENT_FILTERS_QUERY = loader('../../gql/queries/ClientFilters.gql');
const DELETE_CLIENT_FILTER_MUTATION = loader('../../gql/mutations/DeleteClientFilter.gql');

const ClientFilters = ({ activeFilter, onSelect, ...props }) => {
  const [t] = useTranslation();
  const [deletingFilter, setDeletingFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteClientFilter = useMutation(DELETE_CLIENT_FILTER_MUTATION, {
    update: (cache, { data: { deleteClientFilter } }) => {
      const { clientFilters } = cache.readQuery({ query: CLIENT_FILTERS_QUERY });
      cache.writeQuery({
        query: CLIENT_FILTERS_QUERY,
        data: { clientFilters: clientFilters.filter(f => f.id !== deleteClientFilter.id) },
      });
    },
  });

  const deleteFilter = async () => {
    setLoading(true);
    await withGraphQLErrors(() => deleteClientFilter({ variables: { id: deletingFilter.id } }));
    setLoading(false);
    setDeletingFilter(false);
    if (activeFilter && activeFilter.id === deletingFilter.id) onSelect(undefined);
  };

  const { data } = useQuery(CLIENT_FILTERS_QUERY);
  const { clientFilters } = data;

  const items = clientFilters.map(item => ({
    ...item,
    value: item.id,
    deletable: !item.system,
    label: item.name,
    onDelete: () => setDeletingFilter(item),
  }));

  return (
    <div>
      <Select
        colored
        placeholder={t('Фильтры')}
        newLabel={t('Создать фильтр')}
        onAdd={() => onSelect({ name: t('Новый фильтр') })}
        selectedItem={activeFilter && { ...activeFilter, label: activeFilter.name }}
        onChange={onSelect}
        items={items}
        {...props}
      />
      <Modal confirm isOpen={!!deletingFilter} onClose={() => !loading && setDeletingFilter(false)}>
        <Form onSubmit={deleteFilter}>
          <p>
            {t('Вы уверены что хотите удалить фильтр "{{name}}"?', { name: deletingFilter && deletingFilter.name })}
          </p>
          <div>
            <Button type="submit" theme="green" center loading={loading}>
              {t('Удалить')}
            </Button>
            <Button theme="black" onClick={() => setDeletingFilter(false)} disabled={loading}>
              {t('Отмена')}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

ClientFilters.propTypes = {
  activeFilter: PropTypes.object,
  theme: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

ClientFilters.defaultProps = {
  activeFilter: undefined,
  theme: 'toolbar',
};

export default ClientFilters;
