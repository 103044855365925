import React from 'react';
import { useTranslation } from 'react-i18next/hooks';
import { useMutation } from 'react-apollo-hooks';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';

import updateProfileInCache from '../../utils/updateProfileInCache';
import Select from '../Select';
import { getClientFields } from '../ClientFields';

const SET_COLUMNS_MUTATION = loader('../../gql/mutations/SetClientColumns.gql');

const ClientsColumnVisibility = ({ visibleColumns, onChange, ...props }) => {
  const [t] = useTranslation();

  const allColumns = getClientFields({ asList: true });

  const setClientColumns = useMutation(SET_COLUMNS_MUTATION, {
    update: (cache, { data: { setClientColumns } }) => {
      updateProfileInCache(cache, { visibleClientColumns: setClientColumns });
    },
  });

  const changeColumnVisibility = (column, visible) => {
    const columns = visibleColumns;

    const newColumns = visible ? columns.concat([column]) : columns.filter(c => c !== column);
    onChange(newColumns);
    setClientColumns({ variables: { columns: newColumns } });
  };

  return (
    <Select
      label={t('Столбцы')}
      items={allColumns.map(({ label, name }) => ({
        label: label(t),
        type: 'checkbox',
        checked: visibleColumns.includes(name),
        onClick: item => changeColumnVisibility(name, !item.checked),
      }))}
      {...props}
    />
  );
};

ClientsColumnVisibility.propTypes = {
  visibleColumns: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ClientsColumnVisibility;
