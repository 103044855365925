import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Layout from '../Layout';
import Sidebar from '../Sidebar';
import Topbar from '../Topbar';

import s from './CabinetLayout.module.css';

const CabinetLayout = ({ children, title, column }) => (
  <Layout title={title}>
    <Topbar />
    <Sidebar />
    <div className={cn(s.container, { [s.column]: column })}>{children}</div>
  </Layout>
);

CabinetLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  column: PropTypes.bool,
};

CabinetLayout.defaultProps = {
  column: false,
};

export default CabinetLayout;
