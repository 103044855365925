import i18n from '../i18n';

const serverUrl = path => `${process.env.REACT_APP_SERVER_URL}${path}`;

export { serverUrl };

export default async function(method, path, data) {
  const url = serverUrl(`/api${path}`);
  const response = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': i18n.language,
    },
    body: data ? JSON.stringify(data) : '',
  });

  return response.json();
}
