import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import cn from 'classnames';

import { ReactComponent as XIcon } from '../../icons/x.svg';

import s from './Modal.module.css';

Modal.setAppElement('#root');

const StyledModal = ({ title, isOpen, onAfterOpen, onCloseModal, children, onClose, confirm }) => {
  return (
    <Modal
      contentLabel={title}
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterOpen={onAfterOpen}
      portalClassName={s.portal}
      overlayClassName={{ base: s.overlay, afterOpen: s.opened, beforeClose: s.closing }}
      className={{ base: s.content, afterOpen: s.opened, beforeClose: s.closing }}
      closeTimeoutMS={100}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
    >
      <div className={s.close} onClick={onClose}>
        <XIcon />
      </div>
      <div className={cn(s.container, { [s.confirm]: confirm })}>
        {title && <h1 className={s.header}>{title}</h1>}
        {children}
      </div>
    </Modal>
  );
};

StyledModal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAfterOpen: PropTypes.func,
  onCloseModal: PropTypes.func,
  confirm: PropTypes.bool,
};

StyledModal.defaultProps = {
  title: undefined,
  confirm: false,
  onAfterOpen: x => x,
  onCloseModal: x => x,
};

export default StyledModal;
