import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo-hooks';
import Routes from './routes';
import client from './utils/apollo';

import SuspenseLoader from './components/SuspenseLoader';

const App = () => (
  <ApolloProvider client={client}>
    <Suspense fallback={<SuspenseLoader />}>
      <Routes />
    </Suspense>
  </ApolloProvider>
);
ReactDOM.render(<App />, document.getElementById('root'));
