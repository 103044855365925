import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Clients from './clients';
import Login from './login';
import Register from './register';
import Playground from './playground';
import ForgotPassword from './forgot-password';
import { isLoggedIn } from '../utils/auth';

import ProfileLoader from '../components/ProfileLoader';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn() ? (
        <ProfileLoader>
          <Component {...props} />
        </ProfileLoader>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (!isLoggedIn() ? <Component {...props} /> : <Redirect to="/" />)} />
);

const Routes = () => (
  <Router>
    <Switch>
      <PrivateRoute exact path="/clients" component={Clients} />
      <PublicRoute exact path="/login" component={Login} />
      <PublicRoute exact path="/register" component={Register} />
      <PublicRoute exact path="/forgot-password" component={ForgotPassword} />

      {process.env.NODE_ENV === 'development' && <PrivateRoute exact path="/playground" component={Playground} />}

      <Redirect from="/" to="/clients" />
    </Switch>
  </Router>
);

export default Routes;
