import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Downshift from 'downshift';
import { useTranslation } from 'react-i18next/hooks';
import { format, parseISO } from 'date-fns';

import { parseDate } from '../../i18n';
import Calendar from '../Calendar';
import Input from '../Input';

import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg';

import s from './DateInput.module.css';

const DateInput = ({ defaultValue, theme, name, error, disabled, fixedWidth, placeholder, onChange }) => {
  const [t, i18n] = useTranslation();
  const lang = i18n.language;
  const [isOpen, setOpen] = useState(false);
  const mask =
    lang === 'ru'
      ? [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
      : [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  return (
    <Downshift
      itemToString={date => (date ? t('{{date, date}}', { date }) : '')}
      initialSelectedItem={defaultValue && parseISO(defaultValue)}
      isOpen={isOpen}
      onChange={value => onChange(format(value, 'y-MM-dd'))}
      onInputValueChange={onChange}
      onSelect={() => setOpen(false)}
      onOuterClick={() => setOpen(false)}
    >
      {({ getInputProps, getItemProps, getMenuProps, isOpen, selectedItem, selectItem }) => (
        <div className={cn(s.root, s[theme])}>
          <Input
            {...getInputProps({
              mask,
              error,
              theme,
              fixedWidth,
              placeholder,
              disabled: disabled,
              onClick: () => setOpen(!isOpen),
              placeholderChar: '\u2000',
              keepCharPositions: true,
              masked: true,
              active: isOpen,
              rightIcon: <CalendarIcon />,
              onChange: e => {
                const date = parseDate(e.target.value, lang);
                selectItem(isNaN(date) ? undefined : date);
              },
            })}
          />
          <input type="hidden" name={name} defaultValue={selectedItem ? format(selectedItem, 'y-MM-dd') : ''} />
          <Calendar
            theme={theme}
            className={cn(s.calendar, { [s.visible]: isOpen })}
            isOpen={isOpen}
            startCurrentDateAt={selectedItem}
            selectedItem={selectedItem}
            getItemProps={getItemProps}
          />
        </div>
      )}
    </Downshift>
  );
};

DateInput.propTypes = {
  theme: PropTypes.oneOf(['field', 'toolbar-white']),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  fixedWidth: PropTypes.number,
};

DateInput.defaultProps = {
  theme: 'field',
  onChange: () => {},
  placeholder: undefined,
  defaultValue: undefined,
  fixedWidth: undefined,
  disabled: false,
  error: false,
};

export default DateInput;
