import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ReactComponent as CheckedIcon } from '../../icons/checked.svg';

import s from './Checkbox.module.css';

const handleChange = (e, onChange, setChecked) => {
  setChecked(e.target.checked);
  if (onChange) {
    onChange(e);
  }
};

const Checkbox = ({ defaultChecked: initialDefaultChecked, className, children, error, onChange, ...props }) => {
  const [focused, setFocused] = useState(false);
  const [checked, setChecked] = useState(initialDefaultChecked);
  const [defaultChecked, setDefaultChecked] = useState(initialDefaultChecked);

  if (defaultChecked !== initialDefaultChecked) {
    setDefaultChecked(initialDefaultChecked);
    setChecked(initialDefaultChecked);
  }

  return (
    <label
      className={cn(s.root, className, {
        [s.checked]: checked,
        [s.error]: error,
        [s.focused]: focused,
      })}
    >
      <input
        className={s.input}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        type="checkbox"
        onChange={e => handleChange(e, onChange, setChecked)}
        defaultChecked={checked}
        {...props}
      />
      <span className={s.inner}>
        <span className={s.container}>
          <span className={s.box} />
          <CheckedIcon className={s.mark} />
        </span>
        {children && (
          <span className={s.label}>
            {children}
            {error && <span className={s.hint}>{error}</span>}
          </span>
        )}
      </span>
    </label>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node,
  defaultChecked: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  children: undefined,
  defaultChecked: false,
  error: undefined,
  onChange: undefined,
};

export default Checkbox;
