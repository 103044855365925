import React, { useState } from 'react';
import { forEach } from 'ramda';
import { AsYouTypeFormatter } from 'google-libphonenumber';
import Input from '../Input';

const formatter = new AsYouTypeFormatter('RU');

const formatValue = (value, caretPosition = 0) => {
  let digitValue = value.replace(/\D/g, '');
  let formatted = '';
  let caret = 0;

  if (digitValue !== '') {
    digitValue = `+${digitValue}`;

    formatter.clear();
    const positions = [];
    forEach(d => {
      formatted = formatter.inputDigitAndRememberPosition(d);
      positions.push(formatter.getRememberedPosition());
    }, digitValue.split(''));

    let caretDigitIndex = -1;
    for (let i = 0; i < caretPosition; i += 1) {
      if (digitValue[caretDigitIndex + 1] === value[i]) {
        caretDigitIndex += 1;
      }
    }

    caret = caretDigitIndex > -1 ? positions[caretDigitIndex] : 0;
  }
  return { formatted, caret };
};

const handleChange = (value, e, onChange, setValue) => {
  const input = e.target;

  const { formatted, caret } = formatValue(value, input.selectionStart);

  setValue(formatted);

  if (caret > 0) {
    input.setSelectionRange(caret, caret);
  }

  if (onChange) {
    e.persist();
    onChange(value, e);
  }
};

const PhoneInput = ({ onChange, value, defaultValue, ...props }) => {
  const { formatted } = formatValue(value || defaultValue || '');
  const [val, setValue] = useState(formatted);

  return (
    <Input
      {...props}
      maxLength={20}
      type="tel"
      value={val}
      onChange={(value, e) => handleChange(value, e, onChange, setValue)}
    />
  );
};

export default PhoneInput;
