import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next/hooks';

import { ProfileContext, logout } from '../../utils/auth';

import { ReactComponent as HelpIcon } from '../../icons/menu-help.svg';
import { ReactComponent as QuitIcon } from '../../icons/menu-quit.svg';
import { ReactComponent as AvatarIcon } from '../../icons/avatar.svg';

import s from './Topbar.module.css';

const Topbar = ({ history }) => {
  const { user, company } = useContext(ProfileContext);
  const [t] = useTranslation();
  return (
    <div className={s.root}>
      <div className={s.userInfo}>
        <div className={s.userName}>
          {user.fullName}
          <span>{company.name}</span>
        </div>
        <div className={s.userpic}>
          <AvatarIcon />
        </div>
      </div>

      <button className={s.button}>
        <HelpIcon />
        {t('Помощь')}
      </button>
      <button className={s.button} onClick={logout}>
        <QuitIcon />
        {t('Выйти')}
      </button>
    </div>
  );
};

export default withRouter(Topbar);
