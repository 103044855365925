import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next/hooks';

import { ReactComponent as LogoIcon } from '../../icons/logo.svg';
import { ReactComponent as LogoTextIcon } from '../../icons/logo_text.svg';
import s from './AuthPanel.module.css';

const AuthPanel = ({ children }) => {
  const [t, i18n] = useTranslation();

  const isLang = lang => i18n.language === lang;
  const setLang = lang => () => i18n.changeLanguage(lang);

  return (
    <div className={s.root}>
      <div className={s.left}>{children}</div>
      <div className={s.right}>
        <div className={s.switcher}>
          <button className={cn({ [s.selected]: isLang('en') })} onClick={setLang('en')}>
            En
          </button>
          <button className={cn({ [s.selected]: isLang('ru') })} onClick={setLang('ru')}>
            Ru
          </button>
        </div>
        <LogoIcon width="130px" height="140px" />
        <LogoTextIcon color="white" className={s.logoText} />
        <div className={s.description}>{t('E-card платформа для управления программой лояльности')}</div>
      </div>
    </div>
  );
};

AuthPanel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthPanel;
