import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import MaskedInput from 'react-text-mask';

import s from './Input.module.css';

const Input = ({
  theme,
  error,
  masked,
  active,
  leftIcon,
  rightIcon,
  className,
  expandWidth,
  onAutoFill,
  fixedWidth,
  onChange,
  defaultValue,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [originalValue, setOriginalValue] = useState(defaultValue);
  const inputRef = useRef();
  const InputElement = masked ? MaskedInput : 'input';

  const style = {
    minWidth: focused && expandWidth && `${expandWidth}px`,
    width: fixedWidth && `${fixedWidth}px`,
  };

  const typeProps = masked
    ? {
        render: (setRef, props) => (
          <input
            ref={ref => {
              setRef(ref);
              inputRef.current = ref;
            }}
            {...props}
            onBlur={() => setFocused(false)}
          />
        ),
      }
    : { ref: inputRef };

  if (originalValue !== defaultValue) {
    setOriginalValue(defaultValue);
    inputRef.current.value = defaultValue;
  }

  return (
    <div
      className={cn(s.root, className, s[theme], {
        [s.error]: error,
        [s.focused]: focused || active,
        [s.hover]: hovered,
      })}
      style={style}
      onClick={() => inputRef.current.focus()}
    >
      {leftIcon}
      <InputElement
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onAnimationStart={e => e.animationName === s.onAutoFillStart && onAutoFill(e)}
        className={s.input}
        onChange={e => onChange(e.target.value, e)}
        defaultValue={defaultValue}
        {...typeProps}
        {...props}
      />
      {rightIcon}
    </div>
  );
};

Input.propTypes = {
  expandWidth: PropTypes.number,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  theme: PropTypes.oneOf(['field', 'toolbar-white']),
  className: PropTypes.string,
  error: PropTypes.bool,
  masked: PropTypes.bool,
  active: PropTypes.bool,
  fixedWidth: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAutoFill: PropTypes.func,
};

Input.defaultProps = {
  onAutoFill: x => x,
  onChange: x => x,
  theme: 'field',
  masked: false,
  active: false,
  expandWidth: undefined,
  className: undefined,
  leftIcon: undefined,
  rightIcon: undefined,
  fixedWidth: undefined,
  defaultValue: undefined,
  error: false,
};

export default Input;
