import React from 'react';
import http from './http';

const AUTH_TOKENS_KEY = 'lo-token-auth';

let tokens;

const setCookies = ([token]) => {
  const cookie =
    process.env.NODE_ENV === 'production' ? `lo_token=${token};domain=.loyal-up.com;` : `lo_token=${token}`;
  document.cookie = cookie;
};

const readTokens = () => {
  if (tokens) return tokens;
  const item = localStorage.getItem(AUTH_TOKENS_KEY);
  if (!item) return undefined;

  tokens = JSON.parse(item);
  return tokens;
};

const extractToken = (payload, name) => {
  if (!payload) return undefined;
  const [token, expiresAt] = payload[name];
  if (expiresAt * 1000 < Date.now()) return undefined;
  return token;
};

const getRefreshToken = () => {
  const t = readTokens();
  return extractToken(t, 'refresh_token');
};

const setTokens = newTokens => {
  tokens = newTokens;
  localStorage.setItem(AUTH_TOKENS_KEY, JSON.stringify(newTokens));
  console.log();
  setCookies(tokens.access_token);

  return tokens;
};

const resetTokens = () => {
  localStorage.removeItem(AUTH_TOKENS_KEY);
};

const logout = () => {
  resetTokens();
  window.location = '/';
};

const processResponse = response => {
  const { errors } = response;

  if (errors) {
    return [false, errors];
  }

  setTokens(response);
  return [true, getAccessToken()];
};

const login = async fields => {
  const response = await http('POST', '/login', fields);
  return processResponse(response);
};

const register = async fields => {
  const response = await http('POST', '/register', fields);
  return processResponse(response);
};

const getAccessToken = () => {
  const t = readTokens();
  return extractToken(t, 'access_token');
};

const unsafeAccessToken = getAccessToken;

const fetchAccessToken = async () => {
  const accessToken = getAccessToken();

  if (accessToken) return [true, accessToken];

  const token = getRefreshToken();
  if (!token) return [false, 'refresh token is empty or expired'];

  const response = await http('POST', '/refresh', { token });
  return processResponse(response);
};

const isLoggedIn = () => !!getRefreshToken();

const ProfileContext = React.createContext(undefined);

export { isLoggedIn, login, logout, register, fetchAccessToken, unsafeAccessToken, ProfileContext };
