import React from 'react';
import { useTranslation } from 'react-i18next/hooks';

import AuthPanel from '../../components/AuthPanel';
import Form from '../../components/Form';
import Field from '../../components/Field';
import Button from '../../components/Button';
import Link from '../../components/Link';

import Layout from '../../components/Layout';

import s from './ForgotPassword.module.css';

const onSubmit = () => {};

const ForgotPassword = () => {
  const [t] = useTranslation();

  return (
    <Layout center title={t('Восстановление пароля')}>
      <AuthPanel>
        <div className={s.root}>
          <div className={s.header}>
            <h1>{t('Восстановление пароля')}</h1>
          </div>
          <p className={s.hint}>
            {t('Пожалуйста, введите e-mail, указанный вами при регистрации и мы вышлим ссылку для сброса пароля.')}
          </p>
          <Form onSubmit={onSubmit}>
            <Field label={t('E-мейл')} name="text" type="email" autoFocus />
            <Button type="submit" fixedWidth={200} center>
              {t('Отправить письмо')}
            </Button>
          </Form>
          <p className={s.hint}>
            {t('У меня еще нет аккаунта.')}
            <br />
            <Link to="/register">{t('Зарегистрироваться')}</Link>
          </p>
        </div>
      </AuthPanel>
    </Layout>
  );
};

export default ForgotPassword;
