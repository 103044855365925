import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import PhoneInput from '../PhoneInput';
import DateInput from '../DateInput';
import Input from '../Input';
import Select from '../Select';
import ColorPicker from '../ColorPicker';

import s from './Field.module.css';

const isFilled = value => value !== '' && value !== undefined;

const handleChange = (value, e, onChange, setFilled) => {
  setFilled(isFilled(value));

  if (onChange) {
    onChange(value, e);
  }
};

const FIELD_TYPES = {
  phone: PhoneInput,
  date: DateInput,
  color: ColorPicker,
  select: Select,
};

const Field = ({ type, className, label, error, onChange, required, ...props }) => {
  const InputElement = FIELD_TYPES[type] || Input;
  const [isActive, setActive] = useState(false);
  const [filled, setFilled] = useState(isFilled(props.defaultValue || props.value));

  const inputProps =
    type === 'select' || type === 'date'
      ? {
          onChange: item => item && setFilled(true),
          activeChange: status => setActive(status),
          error: !!error,
          stretch: true,
          ...props,
        }
      : {
          className: s.input,
          error: !!error,
          type,
          onAutoFill: () => setFilled(true),
          onChange: (value, e) => handleChange(value, e, onChange, setFilled),
          ...props,
        };

  const fixed = type === 'color';
  const LabelElement = type === 'color' ? 'div' : 'label';
  if (type === 'color') {
    inputProps.className = cn(s.input, s.color);
  }

  return (
    <div className={cn(s.root, className, { [s.error]: error, [s.active]: isActive })}>
      <LabelElement>
        <div className={cn(s.label, { [s.filled]: filled, [s.fixed]: fixed, [s.required]: required })}>
          {label}
          <span className={s.mark}>*</span>
        </div>
        <InputElement {...inputProps} required={required} />
      </LabelElement>
      {error && <div className={s.hint}>{error}</div>}
    </div>
  );
};

Field.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

Field.defaultProps = {
  className: undefined,
  value: undefined,
  error: undefined,
  onChange: undefined,
  required: false,
};

export default Field;
