const fields = [];

const registerField = ({ label, render, ...opts }) => {
  const renderFn = typeof render === 'string' ? obj => obj[render] : render;
  const labelFn = typeof label === 'string' ? t => t(label) : label;
  fields.push({ label: labelFn, render: renderFn, ...opts });
};

const getClientFields = opts => {
  const { asList, filter } = opts || {};

  const filteredFields = filter ? fields.filter(field => filter.includes(field.name)) : fields;
  if (asList) return filteredFields;

  const config = {};
  filteredFields.forEach(field => {
    config[field.name] = field;
  });

  return config;
};

const findClientField = name => fields.find(field => field.name === name);

const isEmpty = value => value === undefined || value === null || value === '';
const withEmpty = (value, fn) => (isEmpty(value) ? undefined : fn(value));

const GENDERS = {
  MALE: 'муж',
  FEMALE: 'жен',
  UNKNOWN: undefined,
};

registerField({
  name: 'email',
  type: 'email',
  render: 'email',
  label: 'E-мейл',
  parse: v => withEmpty(v, v => v),
});

registerField({
  name: 'firstName',
  type: 'text',
  render: 'firstName',
  label: 'Имя',
  parse: v => withEmpty(v, v => v),
});

registerField({
  name: 'lastName',
  type: 'text',
  render: 'lastName',
  label: 'Фамилия',
  parse: v => withEmpty(v, v => v),
});

registerField({
  name: 'phone',
  type: 'phone',
  render: 'phone',
  label: 'Телефон',
  parse: v => withEmpty(v, v => v),
});

registerField({
  name: 'gender',
  type: 'select',
  items: [
    { value: 'UNKNOWN', label: t => t('Не указан') },
    { value: 'MALE', label: t => t('Мужской') },
    { value: 'FEMALE', label: t => t('Женский') },
  ],
  render: (obj, t) => withEmpty(obj.gender, () => GENDERS[obj.gender] && t(GENDERS[obj.gender])),
  label: 'Пол',
  parse: v => (isEmpty(v) ? 'UNKNOWN' : v),
});

registerField({
  name: 'city',
  type: 'text',
  render: 'city',
  label: 'Город',
  parse: v => withEmpty(v, v => v),
});

registerField({
  name: 'discount',
  type: 'number',
  render: obj => withEmpty(obj.discount, () => `${obj.discount}%`),
  label: 'Скидка',
  right: true,
  min: 0,
  max: 100,
  parse: v => withEmpty(v, v => parseFloat(v)),
});

registerField({
  name: 'bonuses',
  type: 'number',
  render: 'bonuses',
  label: 'Бонусы',
  right: true,
  min: 0,
  parse: v => withEmpty(v, v => parseFloat(v)),
});

registerField({
  name: 'bday',
  type: 'date',
  render: (obj, t) => withEmpty(obj.bday, () => t('{{date, date}}', { date: new Date(obj.bday) })),
  label: 'День Рождения',
  parse: v => withEmpty(v, v => v),
});

registerField({
  name: 'extra1',
  type: 'text',
  render: 'extra1',
  label: 'Доп. Поле 1',
  parse: v => withEmpty(v, v => v),
});

registerField({
  name: 'extra2',
  type: 'text',
  render: 'extra2',
  label: 'Доп. Поле 2',
  parse: v => withEmpty(v, v => v),
});

registerField({
  name: 'extra3',
  type: 'text',
  render: 'extra3',
  label: 'Доп. Поле 3',
  parse: v => withEmpty(v, v => v),
});

registerField({
  name: 'extra4',
  type: 'text',
  render: 'extra4',
  label: 'Доп. Поле 4',
  parse: v => withEmpty(v, v => v),
});

registerField({
  name: 'extra5',
  type: 'text',
  render: 'extra5',
  label: 'Доп. Поле 5',
  parse: v => withEmpty(v, v => v),
});

export { getClientFields, findClientField };
