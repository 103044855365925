import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next/hooks';
import { format, parse } from 'date-fns';
import resources from './locales';

import { ru, enUS } from 'date-fns/locale';

const DATE_LOCALES = {
  ru,
  en: enUS,
};

const DATE_FORMATS = {
  date_ru: 'dd.MM.y',
  date_en: 'dd/MM/y',
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: false,

    interpolation: {
      escapeValue: false,
      format: function(value, fmt, lng) {
        if (value instanceof Date && fmt === 'date')
          return format(value, DATE_FORMATS[`${fmt}_${lng}`], { awareOfUnicodeTokens: true });
        return value;
      },
    },
    resources,
  });

const getDateFnsLocale = lng => DATE_LOCALES[lng] || enUS;
const parseDate = (str, lng) => {
  return parse(str, DATE_FORMATS[`date_${lng}`], new Date());
};

export { getDateFnsLocale, DATE_FORMATS, parseDate };
export default i18n;
