import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next/hooks';

import { ReactComponent as MenuIcon } from '../../icons/menu.svg';
import { ReactComponent as ClientsIcon } from '../../icons/menu-clients.svg';
import { ReactComponent as LogoIcon } from '../../icons/logo.svg';
import { ReactComponent as LogoTextIcon } from '../../icons/logo_text.svg';

import s from './Sidebar.module.css';

const NavItem = ({ to, text, icon }) => (
  <NavLink className={s.menuItem} to={to} activeClassName={s.selectedItem}>
    {icon}
    {text}
  </NavLink>
);

const Sidebar = () => {
  const [t] = useTranslation();
  return (
    <nav className={s.root}>
      <div className={s.logo}>
        <MenuIcon className={s.logoMenu} width="19px" height="14px" />
        <LogoIcon className={s.logoIcon} width="28px" height="33px" />
        <LogoTextIcon width="67px" height="17px" />
      </div>
      <NavItem to="/clients" icon={<ClientsIcon />} text={t('Клиенты')} />
    </nav>
  );
};

export default Sidebar;
