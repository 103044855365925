import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import cn from 'classnames';

import * as animationData from './loader.json';

import s from './SuspenseLoader.module.css';

const Loader = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setVisible(true), 350);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={s.loader}>
      <div className={cn(s.loaderContent, { [s.loaderVisible]: visible })}>
        <Lottie options={defaultOptions} height={300} width={300} isClickToPauseDisabled />
      </div>
    </div>
  );
};

export default Loader;
