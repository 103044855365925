import ApolloClient from 'apollo-boost';
import i18n from '../i18n';

import { fetchAccessToken, logout } from './auth';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  request: async operation => {
    const [ok, token] = await fetchAccessToken();
    if (!ok) {
      console.error(token);
      return;
    }

    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
        'Accept-Language': i18n.language,
      },
    });
  },
  onError: ({ graphQLErrors, networkError }) => {
    console.error(graphQLErrors || networkError);

    if (networkError) {
      logout();
    }
  },
});

const withGraphQLErrors = async fn => {
  try {
    return await fn();
  } catch (res) {
    const errors = res.graphQLErrors[0].details;
    return { errors };
  }
};

export { withGraphQLErrors };

export default client;
