import React from 'react';
import PropTypes from 'prop-types';
import serialize from 'form-serialize';
import cn from 'classnames';
import Button from '../Button';
import Checkbox from '../Checkbox';

import s from './Form.module.css';

const handleSubmit = (e, onSubmit) => {
  const formData = serialize(e.target, { empty: true, disabled: true });
  onSubmit(formData, e);
  e.preventDefault();
  return false;
};

const classNameFor = type => {
  if (type === Button) {
    return s.button;
  }

  if (type === Checkbox) {
    return s.checkbox;
  }

  return undefined;
};

const Form = ({ onSubmit, className, containerClassName, layout, children, ...props }) => (
  <form
    className={cn(s.root, className, {
      [s.vertical]: layout === 'vertical',
      [s.horizontal]: layout === 'horizontal',
    })}
    onSubmit={e => handleSubmit(e, onSubmit)}
    {...props}
  >
    {React.Children.map(children, child => (
      <div
        className={cn(
          s.group,
          classNameFor(child.type),
          containerClassName,
          child.props && child.props.containerClassName,
          {
            [s.error]: child.props && child.props.error,
          },
        )}
      >
        {child}
      </div>
    ))}
  </form>
);

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  containerClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  layout: PropTypes.oneOf(['vertical', 'horizontal']),
};

Form.defaultProps = {
  containerClassName: undefined,
  layout: 'vertical',
};

export default Form;
