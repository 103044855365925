import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ReactComponent as MarkIcon } from '../../icons/mark.svg';

import s from './ColorPicker.module.css';

const COLORS = [
  // row 1
  '#d94452',
  '#f76c82',
  '#f5b945',
  '#fbd277',
  '#89c053',
  '#b4df80',
  // row 2
  '#35ba9b',
  '#61ddbc',
  '#4a88da',
  '#73b0f4',
  '#9579da',
  '#b3a4ee',
  // row 3
  '#d56fac',
  '#f199ce',
  '#cad0d7',
  '#abb6c1',
  '#424852',
  '#a9b0bb',
];
const ColorPicker = ({ defaultValue, name, className, onChange }) => {
  const [selectedColor, selectColor] = useState(defaultValue);

  if (!selectedColor) selectColor(COLORS[0]);

  return (
    <div className={cn(s.root, className)}>
      <div className={s.container}>
        {COLORS.map(color => (
          <button
            type="button"
            key={color}
            className={cn(s.color, { [s.selected]: selectedColor === color })}
            style={{ background: color, color: selectedColor === color ? '#fff' : color }}
            onClick={e => {
              selectColor(color);
              onChange(color, e);
            }}
          >
            <MarkIcon />
          </button>
        ))}
      </div>
      <input type="hidden" name={name} defaultValue={selectedColor} />
    </div>
  );
};

ColorPicker.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

ColorPicker.defaultProps = {
  className: undefined,
  defaultValue: undefined,
  onChange: x => x,
};

export default ColorPicker;
