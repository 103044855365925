import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Loader from '../Loader';

import s from './Button.module.css';

const ICONS_CLS = {
  first: 'iconsFirst',
  last: 'iconsLast',
  only: 'iconsOnly',
};

const Button = ({
  type = 'button',
  element,
  theme,
  className,
  loading,
  disabled,
  icons,
  children,
  active,
  stretch,
  center,
  fixedWidth,
  background,
  ...props
}) => {
  const square = theme === 'square';
  const Element = element;
  const iconsAlignClassName = s[ICONS_CLS[icons]];
  const style = fixedWidth ? { width: `${fixedWidth}px` } : {};
  const colorStyle = background ? { background, borderColor: background, color: '#fff' } : {};

  return (
    <Element
      className={cn(s.root, className, iconsAlignClassName, s[theme], {
        [s.active]: active,
        [s.loading]: loading,
        [s.stretch]: stretch,
        [s.center]: center,
      })}
      style={{ ...style, ...colorStyle }}
      type={type}
      disabled={disabled || loading}
      {...props}
    >
      {loading && <Loader className={s.loader} />}
      {square && <div className={s.corner1} />}
      {square && <div className={s.corner2} />}
      {square && <div className={s.corner3} />}
      {square && <div className={s.corner4} />}
      {!square && <div className={s.content}>{children}</div>}
      {!loading && square && <div className={s.container}>{children}</div>}
    </Element>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.string,
  loading: PropTypes.bool,
  stretch: PropTypes.bool,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.string,
  fixedWidth: PropTypes.number,
  background: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  icons: PropTypes.oneOf(['first', 'last', 'only']),
  theme: PropTypes.oneOf(['field', 'square', 'green', 'black', 'toolbar', 'toolbar-white']),
};

Button.defaultProps = {
  icons: 'last',
  element: 'button',
  className: '',
  theme: 'green',
  active: false,
  loading: false,
  stretch: false,
  center: false,
  disabled: false,
  background: undefined,
  fixedWidth: undefined,
};

export default Button;
