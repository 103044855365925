import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactS3Uploader from 'react-s3-uploader';
import cn from 'classnames';

import { unsafeAccessToken } from '../../utils/auth';

import s from './FileUploader.module.css';

const authHeaders = () => ({ authorization: `Bearer ${unsafeAccessToken()}` });

const onPreprocess = (file, next, onStart) => {
  onStart();
  next(file);
};

const uploadFinish = ({ path }, onFinish) => {
  onFinish(path);
};

const FileUploader = forwardRef(({ accept, type, onStart, onFinish, hidden }, ref) => {
  const inputRef = useRef();
  useImperativeHandle(ref, () => ({
    selectFile: () => {
      inputRef.current.click();
    },
  }));

  return (
    <ReactS3Uploader
      className={cn(s.root, { [s.hidden]: hidden })}
      signingUrl={`/storage/presign/${type}`}
      accept={accept}
      preprocess={(file, next) => onPreprocess(file, next, onStart)}
      onFinish={result => uploadFinish(result, onFinish)}
      uploadRequestHeaders={{}}
      signingUrlHeaders={authHeaders}
      server={process.env.REACT_APP_API_URL}
      inputRef={ref => (inputRef.current = ref)}
    />
  );
});

FileUploader.propTypes = {
  accept: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onStart: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
};

FileUploader.defaultProps = {
  hidden: false,
};

export default FileUploader;
