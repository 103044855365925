import React, { useState, useEffect } from 'react';
import Button from '../Button';

const DownloadLink = props => {
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      if (!loading) return;
      const timer = setTimeout(() => setLoading(false), 800);
      return () => {
        clearTimeout(timer);
      };
    },
    [loading],
  );

  return <Button download loading={loading} onClick={() => setLoading(true)} {...props} />;
};

export default DownloadLink;
