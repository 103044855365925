import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useMutation } from 'react-apollo-hooks';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next/hooks';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import debounce from 'debounce';

import { withGraphQLErrors } from '../../utils/apollo';
import Select from '../Select';
import Input from '../Input';
import PhoneInput from '../PhoneInput';
import DateInput from '../DateInput';
import Button from '../Button';
import Modal from '../Modal';
import Field from '../Field';
import Form from '../Form';

import { getClientFields, findClientField } from '../../components/ClientFields';
import { ReactComponent as RemoveIcon } from '../../icons/x.svg';
import { ReactComponent as CreateIcon } from '../../icons/create.svg';

import s from './ClientFilter.module.css';

const SAVE_CLIENT_FILTER_MUTATION = loader('../../gql/mutations/SaveClientFilter.gql');
const SET_TEMP_CLIENT_FILTER_MUTATION = loader('../../gql/mutations/SetTempClientFilter.gql');
const CLIENT_FILTERS_QUERY = loader('../../gql/queries/ClientFilters.gql');

let __id = 1;
const id = () => __id++;

const OPERATORS = [
  {
    value: 'eq',
    label: t => t('Равно'),
    allow: () => true,
  },
  {
    value: 'gt',
    label: t => t('Больше'),
    allow: type => ['date', 'number'].includes(type),
  },
  {
    value: 'lt',
    label: t => t('Меньше'),
    allow: type => ['date', 'number'].includes(type),
  },
  {
    value: 'in',
    label: t => t('Включает'),
    allow: type => ['text', 'email', 'phone'].includes(type),
  },
];
const DEFAULT_ROW = {
  field: 'email',
  operator: 'eq',
  value: '',
};

const MAX_ROWS = 5;

const INPUTS = {
  number: Input,
  text: Input,
  phone: PhoneInput,
  email: Input,
  date: DateInput,
  select: Select,
};

const Row = ({ row: originalRow, isFirst, onRemove, onUpdate, allowRemove }) => {
  const [t] = useTranslation();
  const [row, setRow] = useState(originalRow);
  const fields = getClientFields({ asList: true });
  const { field, operator, value } = row;
  const fieldConfig = findClientField(field);
  const InputElement = INPUTS[fieldConfig.type];
  const inputOpts =
    fieldConfig.type === 'select'
      ? { items: fieldConfig.items, stretch: true, onChange: ({ value }) => updateRow({ ...row, value }) }
      : { type: fieldConfig.type, onChange: debounce(value => updateRow({ ...row, value }), 350) };

  const updateRow = update => {
    const config = findClientField(update.field);
    const sameType = config.type === fieldConfig.type;
    const operator = sameType ? update.operator : 'eq';
    const value = sameType ? update.value : '';
    const updatedRow = { ...update, value, operator };
    if (
      updatedRow.field === row.field &&
      updatedRow.operator === row.operator &&
      (updatedRow.value || '') === (row.value || '')
    )
      return;
    setRow(updatedRow);
    onUpdate(updatedRow);
  };

  return (
    <div className={s.row}>
      <div className={s.where}>{t(isFirst ? 'Где' : 'и где')}</div>
      <Select
        fixedWidth={185}
        theme="toolbar-white"
        className={s.field}
        items={fields.map(item => ({ value: item.name, ...item }))}
        defaultValue={field}
        onChange={({ value }) => updateRow({ ...row, field: value })}
        stretch
      />
      <Select
        fixedWidth={185}
        theme="toolbar-white"
        className={s.field}
        items={OPERATORS.filter(({ allow }) => allow(fieldConfig.type))}
        defaultValue={operator}
        onChange={({ value }) => updateRow({ ...row, operator: value })}
        stretch
      />
      <InputElement
        fixedWidth={185}
        className={s.field}
        theme="toolbar-white"
        defaultValue={value}
        placeholder={t('Значение')}
        {...inputOpts}
      />
      {allowRemove && <RemoveIcon className={s.remove} onClick={onRemove} />}
    </div>
  );
};

const filterOrNewRow = filter => {
  if (!filter) return;

  if (filter.conds) return { ...filter, conds: filter.conds.map(cond => ({ ...cond, id: cond.id || id() })) };
  return { ...filter, conds: [{ ...DEFAULT_ROW, id: 0 }] };
};

const cleanupConds = conds => conds.map(({ field, operator, value }) => ({ field, operator, value }));

const ClientFilter = ({ className, filter, onUpdate, setTempFilter }) => {
  const [t] = useTranslation();
  const [configOpened, openConfig] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [originalFilter, setOriginalFilter] = useState();
  const [currentFilter, setCurrentFilter] = useState(filterOrNewRow(filter));

  const setTempClientFilter = useMutation(SET_TEMP_CLIENT_FILTER_MUTATION);
  const updateClientFilter = useMutation(SAVE_CLIENT_FILTER_MUTATION);
  const addClientFilter = useMutation(SAVE_CLIENT_FILTER_MUTATION, {
    update: (cache, { data: { saveClientFilter } }) => {
      const { clientFilters } = cache.readQuery({ query: CLIENT_FILTERS_QUERY });
      cache.writeQuery({
        query: CLIENT_FILTERS_QUERY,
        data: { clientFilters: clientFilters.concat([saveClientFilter]) },
      });
    },
  });

  if (originalFilter !== filter) {
    setOriginalFilter(filter);
    setCurrentFilter(filterOrNewRow(filter));
  }

  const saveFilter = async filter => {
    if (!filter.name) {
      setErrors({ name: t('введите имя фильтра') });
      return;
    }
    setLoading(true);
    setErrors({});

    const save = filter.id ? updateClientFilter : addClientFilter;
    const form = { ...filter, conds: cleanupConds(filter.conds) };
    const { errors, data } = await withGraphQLErrors(() => save({ variables: form }));
    setLoading(false);

    if (errors) {
      setErrors(errors);
    } else {
      openConfig(false);
      //keep assigned ids for cods
      onUpdate({ ...data.saveClientFilter, conds: filter.conds });
    }
  };

  const refreshTempFilter = async () => {
    if (currentFilter) {
      const conds = cleanupConds(currentFilter.conds);
      const { data } = await setTempClientFilter({ variables: { conds } });
      setTempFilter(data.setTempClientFilter);
    } else {
      setTempFilter(undefined);
    }
  };

  useEffect(
    () => {
      refreshTempFilter();
    },
    [currentFilter],
  );

  const { conds, system } = currentFilter || { conds: [] };

  return (
    <div className={cn(s.root, className)}>
      <ReactCSSTransitionGroup
        component="div"
        className={s.rows}
        transitionName={{
          enter: s.rowEnter,
          enterActive: s.rowEnterActive,
          leave: s.rowLeave,
          leaveActive: s.rowLeaveActive,
        }}
        transitionEnterTimeout={150}
        transitionLeaveTimeout={150}
      >
        {conds.map((row, index) => (
          <Row
            key={row.id}
            onRemove={() => setCurrentFilter({ ...currentFilter, conds: conds.filter((_, i) => i !== index) })}
            onUpdate={update => {
              const newConds = conds.slice();
              newConds.splice(index, 1, update);
              setCurrentFilter({ ...currentFilter, conds: newConds });
            }}
            row={row}
            isFirst={index === 0}
            allowRemove={conds.length > 1}
          />
        ))}
      </ReactCSSTransitionGroup>
      <div className={s.buttons}>
        <div className={s.where}>
          <Button
            theme="green"
            icons="only"
            disabled={system || conds.length === MAX_ROWS}
            onClick={() => setCurrentFilter({ ...currentFilter, conds: conds.concat([{ ...DEFAULT_ROW, id: id() }]) })}
          >
            <CreateIcon />
          </Button>
        </div>
        <Button
          className={s.button}
          theme="green"
          fixedWidth={185}
          disabled={system}
          loading={loading}
          center
          onClick={() => (currentFilter.id ? saveFilter(currentFilter) : openConfig(true))}
        >
          {t('Сохранить фильтр')}
        </Button>
        <Button className={s.button} theme="black" onClick={() => onUpdate(undefined)} disabled={loading}>
          {t('Отменить')}
        </Button>
      </div>
      <Modal title={t('Создание фильтра')} isOpen={configOpened} onClose={() => !loading && openConfig(false)}>
        <Form onSubmit={fields => saveFilter({ ...currentFilter, ...fields })}>
          <Field autoFocus required label={t('Название')} name="name" type="text" error={errors.name} />
          <Field label={t('Цвет')} name="color" type="color" />
          <div>
            <Button type="submit" theme="green" center loading={loading}>
              {t('Создать')}
            </Button>
            <Button theme="black" onClick={() => openConfig(false)} disabled={loading}>
              {t('Отмена')}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

ClientFilter.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  setTempFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    color: PropTypes.string,
    conds: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string,
        operator: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  }),
};

export default ClientFilter;
