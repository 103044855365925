import React from 'react';
import cn from 'classnames';
import Kalendaryo from 'kalendaryo';
import { isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next/hooks';

import { ReactComponent as DropTickIcon } from '../../icons/drop-tick.svg';
import { ReactComponent as PrevIcon } from '../../icons/prev.svg';

import { getDateFnsLocale } from '../../i18n';

import { isSameMonth } from 'date-fns';

import s from './Calendar.module.css';

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

const CalendarWidget = ({
  getFormattedDate,
  getWeeksInMonth,
  getDayLabelsInWeek,
  setDatePrevMonth,
  setDateNextMonth,
  getItemProps,
  selectedItem,
  setDate,
  pickDate,
  date,
  t,
  className,
  isOpen,
  theme,
}) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const weeksInCurrentMonth = getWeeksInMonth();
  const dayLabels = getDayLabelsInWeek('eeeeee');
  const gItemProprs = getItemProps || (props => props);
  const today = new Date();

  const isSelectedDay = date => selectedItem && getFormattedDate(selectedItem) === getFormattedDate(date);

  if (!isOpen && selectedItem && date !== selectedItem) {
    // hackish but that works.
    setTimeout(() => setDate(selectedItem));
  }

  const months = [];
  for (let i = 0; i <= 11; i++) {
    months.push(new Date(year, i, 1));
  }

  const years = [];
  const nowYear = new Date().getFullYear();
  for (let i = 0; i <= 100; i++) {
    years.push(new Date(nowYear - i, month, 1));
  }

  return (
    <div className={cn(s.root, className, s[theme])}>
      <div className={s.header}>
        <button type="button" className={s.prev} onClick={setDatePrevMonth} disabled={!isOpen}>
          <PrevIcon />
        </button>

        <div className={s.select}>
          <select value={date.getMonth()} onChange={e => setDate(new Date(year, e.target.value, 1))} disabled={!isOpen}>
            {months.map((d, index) => (
              <option value={d.getMonth()} key={index}>
                {capitalize(getFormattedDate(d, 'LLLL'))}
              </option>
            ))}
          </select>
          <DropTickIcon />
        </div>
        <div className={s.select}>
          <select
            value={date.getFullYear()}
            onChange={e => setDate(new Date(e.target.value, month, 1))}
            disabled={!isOpen}
          >
            {years.map((d, index) => (
              <option value={d.getFullYear()} key={index}>
                {getFormattedDate(d, 'y')}
              </option>
            ))}
          </select>
          <DropTickIcon />
        </div>

        <button type="button" className={s.next} onClick={setDateNextMonth} disabled={!isOpen}>
          <PrevIcon />
        </button>
      </div>

      <div className={cn(s.wdays, s.wheader)}>
        {dayLabels.map(label => (
          <div className={s.day} key={label}>
            {capitalize(label)}
          </div>
        ))}
      </div>

      {weeksInCurrentMonth.map((week, i) => (
        <div className={s.wdays} key={i}>
          {week.map(day => (
            <div
              {...gItemProprs({
                key: day.label,
                item: day.dateValue,
                onClick: e => {
                  // fix issue with putting select inside <label> (see Field)
                  e.preventDefault();
                },
                className: cn(s.day, {
                  [s.otherMonth]: !isSameMonth(date, day.dateValue),
                  [s.selected]: isSelectedDay(day.dateValue),
                  [s.today]: isSameDay(today, day.dateValue),
                }),
              })}
            >
              {day.label}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const Calendar = opts => {
  const [t, i18n] = useTranslation();
  const locale = getDateFnsLocale(i18n.language);
  return <Kalendaryo render={CalendarWidget} startWeekAt={1} locale={locale} t={t} {...opts} />;
};

export default Calendar;
