import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next/hooks';

import Layout from '../../components/Layout';
import AuthPanel from '../../components/AuthPanel';
import Form from '../../components/Form';
import Field from '../../components/Field';
import Link from '../../components/Link';
import Button from '../../components/Button';
import { ReactComponent as FbIcon } from '../../icons/fb.svg';
import { ReactComponent as VkIcon } from '../../icons/vk.svg';
import { login } from '../../utils/auth';

import s from './Login.module.css';

const onSubmit = async (fields, setLoading, setErrors, history) => {
  setLoading(true);
  setErrors({});
  const [ok, errors] = await login(fields);

  if (!ok) {
    setErrors(errors);
    setLoading(false);
    return;
  }

  history.push('/');
};

const Login = ({ history }) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  return (
    <Layout center title={t('Авторизация')}>
      <AuthPanel>
        <div className={s.root}>
          <div className={s.header}>
            <h1>{t('Авторизация')}</h1>
            <FbIcon />
            <VkIcon />
          </div>
          <Form onSubmit={fields => onSubmit(fields, setLoading, setErrors, history)}>
            <Field label={t('E-мейл')} name="email" type="email" autoFocus disabled={loading} error={errors.email} />
            <Field label={t('Пароль')} name="password" type="password" disabled={loading} error={errors.password} />
            <Link to="/forgot-password">{t('Забыли пароль?')}</Link>
            <Button type="submit" fixedWidth={200} center loading={loading}>
              {t('Войти')}
            </Button>
          </Form>
          <p className={s.hint}>
            {t('У меня еще нет аккаунта.')}
            <br />
            <Link to="/register">{t('Зарегистрироваться')}</Link>
          </p>
        </div>
      </AuthPanel>
    </Layout>
  );
};

export default withRouter(Login);
