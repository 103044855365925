import React from 'react';
import { useMutation } from 'react-apollo-hooks';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';

import updateProfileInCache from '../../utils/updateProfileInCache';
import Select from '../Select';

import { ReactComponent as ListIcon } from '../../icons/list.svg';

import s from './ClientsPageSize.module.css';

const SET_LIMIT_MUTATION = loader('../../gql/mutations/SetClientPageSize.gql');

const ClientsPageSize = ({ selected, onChange, ...props }) => {
  const setClientLimit = useMutation(SET_LIMIT_MUTATION, {
    update: (cache, { data: { setClientPageSize } }) => {
      updateProfileInCache(cache, { clientPageSize: setClientPageSize });
    },
  });

  return (
    <Select
      label={
        <div className={s.label}>
          <div className={s.content}>
            <ListIcon />
            {selected}
          </div>
        </div>
      }
      items={[10, 50, 100].map(limit => ({
        label: limit,
        onClick: () => {
          setClientLimit({ variables: { limit } });
          onChange(limit);
        },
      }))}
      {...props}
    />
  );
};

ClientsPageSize.propTypes = {
  selected: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ClientsPageSize;
