import React, { useRef, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next/hooks';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { format } from 'date-fns';
import cn from 'classnames';
import debounce from 'debounce';

import CabinetLayout from '../../components/CabinetLayout';
import Select from '../../components/Select';
import FileUploader from '../../components/FileUploader';
import Table from '../../components/Table';
import Button from '../../components/Button';
import DownloadLink from '../../components/DownloadLink';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import ClientForm from '../../components/ClientForm';
import ClientsPageSize from '../../components/ClientsPageSize';
import ClientsColumnVisibility from '../../components/ClientsColumnVisibility';
import ClientFilters from '../../components/ClientFilters';
import ClientFilter from '../../components/ClientFilter';
import { getClientFields } from '../../components/ClientFields';

import { ProfileContext } from '../../utils/auth';
import { serverUrl } from '../../utils/http';
import updateProfileInCache from '../../utils/updateProfileInCache';

import { ReactComponent as DocsIcon } from '../../icons/docs.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as UploadIcon } from '../../icons/upload.svg';
import { ReactComponent as CsvIcon } from '../../icons/csv.svg';
import { ReactComponent as ExportIcon } from '../../icons/export.svg';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';

import s from './Clients.module.css';

const CLIENTS_QUERY = loader('../../gql/queries/Clients.gql');
const IMPORT_CSV_MUTATION = loader('../../gql/mutations/ImportClientsCsv.gql');
const SET_ORDER_MUTATION = loader('../../gql/mutations/SetClientOrder.gql');

const Clients = () => {
  const { clientsCount, clientOrder, clientPageSize, visibleClientColumns } = useContext(ProfileContext);
  const anyClients = clientsCount > 0;

  const uploadRef = useRef();
  const [t] = useTranslation();

  const [defaultTableConfig] = useState({ offset: 0, limit: clientPageSize, ...clientOrder });
  const [activeFilter, setActiveFilter] = useState(undefined);
  const [activeClient, setActiveClient] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(visibleClientColumns);
  const [tableConfig, setTableConfig] = useState(defaultTableConfig);

  const columns = getClientFields({ asList: true, filter: visibleColumns });

  const { loading, data, fetchMore } = useQuery(CLIENTS_QUERY, {
    suspend: false,
    variables: defaultTableConfig,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(
    () => {
      fetchMore({
        variables: tableConfig,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return fetchMoreResult;
        },
      });
      return undefined;
    },
    [tableConfig],
  );

  const importFromCsv = useMutation(IMPORT_CSV_MUTATION, {
    update: (cache, { data: { importFromCsv } }) => {
      updateProfileInCache(cache, { clientsCount: importFromCsv.totalCount });
      setUploading(false);
      setTableConfig({ ...tableConfig, offset: 0 });
    },
  });

  const setClientOrder = useMutation(SET_ORDER_MUTATION, {
    update: (cache, { data: { setClientOrder } }) => {
      updateProfileInCache(cache, { clientOrder: setClientOrder });
    },
  });

  return (
    <CabinetLayout title={t('Клиенты')} column>
      <div className={s.header}>
        <h1>{t('Клиенты')}</h1>
        <div className={s.spacer} />
        {anyClients && (
          <div className={s.toolbarItem}>
            <Input
              theme="toolbar-white"
              expandWidth={300}
              leftIcon={<SearchIcon />}
              type="text"
              placeholder={t('Поиск')}
              onChange={debounce(text => setTableConfig({ ...tableConfig, text, offset: 0 }), 300)}
            />
          </div>
        )}
        {anyClients && (
          <Select
            theme="green"
            className={s.toolbarItem}
            loading={uploading}
            label={t('Добавить клиентов')}
            items={[
              { label: t('Добавить вручную'), onClick: () => setActiveClient({}) },
              { label: t('Импорт из CSV'), onClick: () => uploadRef.current.selectFile() },
            ]}
          />
        )}
        <FileUploader
          hidden
          accept="text/csv"
          type="csv"
          onStart={() => setUploading(true)}
          onFinish={path => importFromCsv({ variables: { path } })}
          ref={uploadRef}
        />
      </div>
      {anyClients && (
        <div className={s.toolbar}>
          <ClientFilters
            theme="toolbar-white"
            stretch
            fixedWidth={220}
            activeFilter={activeFilter}
            onSelect={filter => setActiveFilter(filter)}
          />
          <div className={s.spacer} />
          <ClientsPageSize
            className={s.toolbarItem}
            theme="toolbar-white"
            selected={tableConfig.limit}
            onChange={limit => setTableConfig({ ...tableConfig, limit })}
          />
          <ClientsColumnVisibility
            className={s.toolbarItem}
            fixedWidth={180}
            theme="toolbar-white"
            stretch
            visibleColumns={visibleColumns}
            onChange={setVisibleColumns}
          />
          <DownloadLink
            className={s.toolbarItem}
            theme="toolbar"
            element="a"
            icons="first"
            href={serverUrl(
              `/clients/export/${format(new Date(), 'y-MM-d')}.csv?filterId=${tableConfig.filterId ||
                ''}&text=${tableConfig.text || ''}`,
            )}
          >
            <ExportIcon />
            {t('Экспорт в CSV')}
          </DownloadLink>
        </div>
      )}
      {anyClients && (
        <ClientFilter
          className={cn(s.filter, { [s.visible]: !!activeFilter })}
          filter={activeFilter}
          onUpdate={filter => setActiveFilter(filter)}
          setTempFilter={filterId => setTableConfig({ ...tableConfig, filterId, offset: 0 })}
        />
      )}
      {anyClients ? (
        <Table
          total={data.clients && data.clients.total}
          tableConfig={tableConfig}
          columns={columns}
          rows={(data.clients && data.clients.clients) || []}
          loading={loading}
          highlightText={tableConfig.text}
          onRowSelect={client => setActiveClient(client)}
          onLoad={config => {
            const { orderBy, orderDirection } = config;
            if (tableConfig.orderBy !== orderBy || tableConfig.orderDirection !== orderDirection) {
              setClientOrder({ variables: { orderBy, orderDirection } });
            }
            setTableConfig(config);
          }}
        />
      ) : (
        <div className={s.placeholderContainer}>
          <div className={s.placeholderButtons}>
            <Button theme="square" onClick={() => setActiveClient({})}>
              <PlusIcon />
              <DocsIcon />
              <span>{t('Добавить вручную')}</span>
            </Button>
            <Button theme="square" onClick={() => uploadRef.current.selectFile()} loading={uploading}>
              <UploadIcon />
              <CsvIcon />
              <span>{t('Загрузить файл')}</span>
            </Button>
          </div>
          <div className={s.placeholderText}>
            {t('У вас пока еще нет добавленных клиентов, вы можете добавить их вручную или загрузить из CSV-файла')}
          </div>
        </div>
      )}
      <Modal
        title={(activeClient || {}).id ? activeClient.fullName : t('Добавить вручную')}
        isOpen={!!activeClient}
        onClose={() => setActiveClient(false)}
      >
        <ClientForm
          client={activeClient || {}}
          afterSave={() => {
            if (!activeClient.id) {
              setTableConfig({ ...tableConfig, offset: 0 });
            }
            setActiveClient(false);
          }}
          onCancel={() => setActiveClient(false)}
        />
      </Modal>
    </CabinetLayout>
  );
};

export default Clients;
